"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LastRenewAttemptLocalWrapper = void 0;
const luxon_1 = require("luxon");
class LastRenewAttemptLocalWrapper {
    constructor(storageKey) {
        this.storageKey = storageKey;
    }
    async getLastRenewedAttempt() {
        const storedData = localStorage.getItem(this.storageKey);
        if (!storedData) {
            return null;
        }
        const parsedData = JSON.parse(storedData);
        return {
            remainingAds: parsedData.remainingAds,
            date: luxon_1.DateTime.fromISO(parsedData.date),
        };
    }
    async saveLastRenewedAttempt(remainingAds) {
        const dataToStore = {
            remainingAds,
            date: luxon_1.DateTime.now().toISO(),
        };
        localStorage.setItem(this.storageKey, JSON.stringify(dataToStore));
    }
    async deleteLastRenewedAttempt() {
        localStorage.removeItem(this.storageKey);
    }
}
exports.LastRenewAttemptLocalWrapper = LastRenewAttemptLocalWrapper;
