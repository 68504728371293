"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.waitForTimeout = waitForTimeout;
exports.getRandBetween = getRandBetween;
exports.scrollToBottom = scrollToBottom;
exports.htmlIsPresent = htmlIsPresent;
exports.textIsPresent = textIsPresent;
exports.createIframe = createIframe;
exports.needToRenew = needToRenew;
const luxon_1 = require("luxon");
async function waitForTimeout(milliseconds) {
    await new Promise((r) => setTimeout(r, milliseconds));
}
function getRandBetween(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
function scrollToBottom() {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
}
function htmlIsPresent(htmlToSearch) {
    return document.body.innerHTML.includes(htmlToSearch);
}
function textIsPresent(textToSearch) {
    return document.body.innerHTML.includes(textToSearch);
}
function createIframe(url) {
    const iframe = document.createElement("iframe");
    iframe.src = url;
    document.body.appendChild(iframe);
}
/**
 * @returns {boolean} true when the passed date is older than one day
 */
function needToRenew(someday) {
    const today = luxon_1.DateTime.now();
    // Check if the date is one day in the past or more
    if (someday.diff(today, "days").days < -0.99) {
        return true;
    }
    return false;
}
