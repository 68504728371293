"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LastRenewAttemptApiWrapper = void 0;
const last_renewed_rest_client_1 = require("last-renewed-rest-client");
const luxon_1 = require("luxon");
const itemRestClient = new last_renewed_rest_client_1.ItemRestClient();
/**
 * @deprecated use the one that uses local storage as it does not consume AWS quota
 */
class LastRenewAttemptApiWrapper {
    constructor(lratPk) {
        this.lratPk = lratPk;
    }
    async getLastRenewedAttempt() {
        const item = await itemRestClient.getItem(this.lratPk);
        if (!item) {
            return null;
        }
        return {
            // @ts-ignore
            remainingAds: item.remainingAds,
            date: luxon_1.DateTime.fromISO(item.date)
        };
    }
    async saveLastRenewedAttempt(remainingAds) {
        const item = {
            pk: this.lratPk,
            sk: 'sample',
            remainingAds,
            date: luxon_1.DateTime.now()
        };
        await itemRestClient.saveItem(item);
    }
    async deleteLastRenewedAttempt() {
        await itemRestClient.deleteItem(this.lratPk);
    }
}
exports.LastRenewAttemptApiWrapper = LastRenewAttemptApiWrapper;
