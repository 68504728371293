"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const renew_manager_1 = require("renew-manager");
const uuid_1 = require("uuid");
const config_1 = require("./config");
const listener_service_1 = require("./listener-service");
const renew_1 = require("./renew");
const utils_1 = require("./utils");
const HOURS_NEED_TO_RENEW = 23;
const renewManager = new renew_manager_1.RenewManager(HOURS_NEED_TO_RENEW);
class WallapopBot {
    constructor(lastRenewedAttemptManager, lastRenewedRestClient) {
        this.lastRenewedAttemptManager = lastRenewedAttemptManager;
        this.lastRenewedRestClient = lastRenewedRestClient;
        this.instanceId = (0, uuid_1.v4)();
    }
    async getLastRenewed() {
        const lr = await this.lastRenewedRestClient.getLastRenewed(config_1.botName);
        if (lr == null) {
            console.error('There is no last renewed... we just return now');
            return luxon_1.DateTime.now();
        }
        return lr;
    }
    /**
     * Will attempt to double check the last renewed of the ads to match the one from the cloud.
     * If they do not match it will save it.
     */
    async doubleCheckLastRenewedAndSaveRenew() {
        await (0, utils_1.waitForTimeout)(1.5 * 1000);
        const anuncis = (0, renew_1.getAnuncisWrapper)();
        // Getting the oldest lastModificado
        const oldestAnunci = anuncis.reduce((oldest, current) => {
            return oldest.lastModificado < current.lastModificado ? oldest : current;
        });
        const lastRenewed = await this.getLastRenewed();
        console.debug(lastRenewed.toISODate());
        if (oldestAnunci.lastModificado > lastRenewed) {
            console.log('saving the last renewed to the cloud, as it is after the stored one');
            await this.lastRenewedRestClient.saveLastRenewed(config_1.botName, oldestAnunci.lastModificado.toISO());
        }
    }
    /**
     * Prints the feedback message, if renewed or not
     */
    async persistLastRenewedIfSuccessful() {
        let saved = false;
        if (await this.lastRenewedAttemptManager.isLastRenewedAttemptRecent()) {
            if (window.finished) {
                saved = true;
                console.log('saving into last renewed rest client');
                await this.lastRenewedRestClient.saveLastRenewed(config_1.botName);
                await this.lastRenewedAttemptManager.deleteLastRenewedAttempt(); //delete the last renewed attempt after save
            }
        }
        // Feedback message
        console.log(`${!saved ? 'NOT' : ''} persisted into ${config_1.botName} last renewed`);
        // Fallback, to double check if they were renewed but they were not persisted
        if (!saved) {
            await this.doubleCheckLastRenewedAndSaveRenew();
        }
    }
    async runBot() {
        console.log(`running bot: ${this.instanceId}`);
        await (0, renew_1.mainRenewWrapper)();
        await this.persistLastRenewedIfSuccessful();
    }
    async addListeners() {
        console.log('addListeners');
        (0, listener_service_1.addHashChangeListener)(this.runBot);
        await (0, listener_service_1.addNavigateListener)(this.lastRenewedAttemptManager);
    }
    async needToRenew() {
        const lastRenewed = await this.getLastRenewed();
        console.debug(lastRenewed.toISODate());
        return renewManager.needToRenew(lastRenewed);
    }
}
exports.default = WallapopBot;
