"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const bot_factory_1 = require("./bot-factory");
const utils_1 = require("./utils");
function closeFeatureBannerIfPresent() {
    const el = document.querySelector("wallapop-feature-banner");
    if (!el) {
        return;
    }
    el.style.display = 'none';
}
const bot = (0, bot_factory_1.createBot)();
void (async () => {
    await (0, utils_1.waitForTimeout)(1000);
    closeFeatureBannerIfPresent();
    if (!await bot.needToRenew()) {
        console.log(`last run is still recent. Aborting run`);
        return;
    }
    await bot.addListeners();
    await bot.runBot();
})();
