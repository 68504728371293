"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WALLAPOP_LIST_URL = exports.NAVIGATED_TO_CHECKOUT_STR = exports.DATETIME_FORMAT = exports.lastRenewAttemptClientMode = exports.LAST_RENEW_ATTEMPT_MODE = exports.LogLevel = exports.gmailOAuthUser = exports.botName = exports.lratPk = void 0;
//------------- CONSTANTS -----------------
exports.lratPk = 'LRAT#Wallapop';
exports.botName = 'Wallapop';
//-------------END CONSTANTS --------------
exports.gmailOAuthUser = 'josepalsinasio@gmail.com';
var LogLevel;
(function (LogLevel) {
    LogLevel["debug"] = "debug";
    LogLevel["info"] = "info";
})(LogLevel || (exports.LogLevel = LogLevel = {}));
var LAST_RENEW_ATTEMPT_MODE;
(function (LAST_RENEW_ATTEMPT_MODE) {
    LAST_RENEW_ATTEMPT_MODE["local"] = "local";
    LAST_RENEW_ATTEMPT_MODE["cloud"] = "cloud";
})(LAST_RENEW_ATTEMPT_MODE || (exports.LAST_RENEW_ATTEMPT_MODE = LAST_RENEW_ATTEMPT_MODE = {}));
exports.lastRenewAttemptClientMode = LAST_RENEW_ATTEMPT_MODE.local;
/**
 * The luxon datetime format present in Wallapop page
 */
exports.DATETIME_FORMAT = "dd/MM/yyyy";
exports.NAVIGATED_TO_CHECKOUT_STR = "Resumen de tu compra";
exports.WALLAPOP_LIST_URL = "https://es.wallapop.com/app/catalog/published";
