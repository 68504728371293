"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LastRenewAttemptManager = void 0;
const last_renew_attempt_api_wrapper_1 = require("./api/last-renew-attempt-api-wrapper");
const last_renew_attempt_local_wrapper_1 = require("./api/last-renew-attempt-local-wrapper");
const config_1 = require("./config");
class LastRenewAttemptManager {
    constructor(lratPk) {
        if (config_1.LAST_RENEW_ATTEMPT_MODE.local.valueOf() == config_1.lastRenewAttemptClientMode.valueOf()) {
            this.lastRenewAttemptClient = new last_renew_attempt_local_wrapper_1.LastRenewAttemptLocalWrapper(lratPk);
            return;
        }
        if (config_1.LAST_RENEW_ATTEMPT_MODE.cloud.valueOf() == config_1.lastRenewAttemptClientMode.valueOf()) {
            this.lastRenewAttemptClient = new last_renew_attempt_api_wrapper_1.LastRenewAttemptApiWrapper(lratPk);
            return;
        }
        throw new Error('invalid config lastRenewAttemptClientMode');
    }
    /**
     * @returns true when the last renew attempt was less than or equal 5 minutes ago
     */
    async isLastRenewedAttemptRecent() {
        const lastRenewedAttempt = await this.lastRenewAttemptClient.getLastRenewedAttempt();
        if (!lastRenewedAttempt) {
            return false;
        }
        const minutesAgoLastAttempt = Math.abs(lastRenewedAttempt.date.diffNow('minutes').minutes);
        console.log('minutesAgoLastAttempt = ', Math.round(minutesAgoLastAttempt));
        return minutesAgoLastAttempt <= 5;
    }
    async saveLastRenewedAttempt(remainingAds) {
        if (remainingAds > 0) {
            return await this.lastRenewAttemptClient.saveLastRenewedAttempt(remainingAds);
        }
        // remaning ads is 0, we should check if is last renewed attempt before 5 mintues
        const isLRATRecent = await this.isLastRenewedAttemptRecent();
        if (isLRATRecent) {
            return await this.lastRenewAttemptClient.saveLastRenewedAttempt(remainingAds);
        }
        else {
            // In here it means that we access the website another time after the ads have been renewed
            console.log('last renew attempt: not saving as a renew attempt as anuncis were already renewed before this "session"');
        }
    }
    async deleteLastRenewedAttempt() {
        return await this.lastRenewAttemptClient.deleteLastRenewedAttempt();
    }
}
exports.LastRenewAttemptManager = LastRenewAttemptManager;
